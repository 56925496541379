import '../styles/globals.scss';
import '../styles/ui.scss';
import '../styles/wordpress.scss';
import '../styles/pages/terms_conditions.scss';
import '../styles/pages/feedaty.scss';
import 'react-multi-carousel/lib/styles.css';
import Layout from '../components/Layout';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from '../public/locales/en.json';
import translationIt from '../public/locales/it.json';
import translationDe from '../public/locales/de.json';
import translationFr from '../public/locales/fr.json';
import translationNo from '../public/locales/no.json';
import translationSv from '../public/locales/sv.json';
import translationCz from '../public/locales/cs.json';
import translationSk from '../public/locales/sk.json';
import translationNl from '../public/locales/nl.json';
import translationPl from '../public/locales/pl.json';
import translationPt from '../public/locales/pt.json';
import translationRu from '../public/locales/ru.json';
import translationHu from '../public/locales/hu.json';
import translationDa from '../public/locales/da.json';
import translationEs from '../public/locales/es.json';
import translationZh from '../public/locales/zh.json';
import translationJa from '../public/locales/ja.json';
import translationKo from '../public/locales/ko.json';

import moment from 'moment';
import { DEFAULT_LANG, getLangCountry } from '../helpers/languages';
import LanguageDetector from 'i18next-browser-languagedetector';
import { UynAppProps } from '../models/app';
import { AlertProvider } from '../context/AlertProvider';
import dynamic from 'next/dynamic';
import GoogleTagManagerBody from '../components/GoogleTagManagerBody';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import * as gTag from '../lib/gtag';
import { Provider } from 'react-redux';
import store from '../context/store';
import AdviseCountry from '../components/AdviseCountry';
import PopupAlertShipping from '../components/PopupAlertShipping';
import { useCookies } from 'react-cookie';
import { DEFAULT_COUNTRY } from '../helpers/countries';
import { createSession, fetchSession } from '../fetchers/sessions';
import {
  SET_PROFILE,
  SET_SESSION_DATA,
  SET_SESSION_TOKEN
} from '../context/types';
import { fetchProfileSettings } from '../fetchers/profile';
import { logoutActions } from '../helpers/logout';
import { getCookieLogged } from '../helpers/session';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const ChatWithNoSSR = dynamic(() => import('../components/Chat'), {
  ssr: false
});

declare global {
  interface Window {
    gtag: any;
    fbq: any;
  }
}

// var _iub = _iub || [];
// _iub.csConfiguration = { "consentOnContinuedBrowsing": false, "whitelabel": false, "lang": "it", "cookiePolicyId": 28601124, "siteId": 2352888, "banner": { "acceptButtonDisplay": true, "customizeButtonDisplay": true, "position": "float-top-center", "acceptButtonColor": "#0073CE", "acceptButtonCaptionColor": "white", "customizeButtonColor": "#DADADA", "customizeButtonCaptionColor": "#4D4D4D", "rejectButtonColor": "#0073CE", "rejectButtonCaptionColor": "white", "textColor": "black", "backgroundColor": "white" } };

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEn
      },
      it: {
        translation: translationIt
      },
      de: {
        translation: translationDe
      },
      fr: {
        translation: translationFr
      },
      no: {
        translation: translationNo
      },
      sv: {
        translation: translationSv
      },
      cz: {
        translation: translationCz
      },
      sk: {
        translation: translationSk
      },
      nl: {
        translation: translationNl
      },
      pl: {
        translation: translationPl
      },
      pt: {
        translation: translationPt
      },
      ru: {
        translation: translationRu
      },
      hu: {
        translation: translationHu
      },
      da: {
        translation: translationDa
      },
      es: {
        translation: translationEs
      },
      ko: {
        translation: translationKo
      },
      ja: {
        translation: translationJa
      },
      zh: {
        translation: translationZh
      },
      us: {
        translation: translationEn
      }
    },
    fallbackLng: DEFAULT_LANG,
    interpolation: {
      escapeValue: false
    }
  });

const UynApp = ({ Component, pageProps }: UynAppProps) => {
  const router = useRouter();
  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 5000,
          refetchOnWindowFocus: false,
          networkMode: 'offlineFirst'
        }
      }
    });
  });

  const { lang = DEFAULT_LANG, country = DEFAULT_COUNTRY } = getLangCountry(
    router.query
  );
  const [cookies, setCookie, removeCookie] = useCookies([
    'uyn_bearer',
    'uyn_country'
  ]);

  i18next.changeLanguage(lang);
  moment.locale(lang);

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      /* invoke analytics function only for production */
      gTag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    setCookie(
      'uyn_country',
      country && country !== 'undefined' ? country : DEFAULT_COUNTRY,
      { path: '/' }
    );

    setupSession();
  }, []);

  const getNewSessionToken = async (): Promise<string> => {
    const { headers = {} } = await createSession({ country });

    return headers['uyn-bearer'];
  };

  const setupSession = async () => {
    let token = cookies.uyn_country !== country ? '' : cookies['uyn_bearer'];

    const { data } = await fetchSession({
      token,
      country,
      lang
    });

    if (data) {
      store.dispatch({ type: SET_SESSION_DATA, payload: data.session });
      store.dispatch({ type: SET_SESSION_TOKEN, payload: token });
    } else {
      token = await getNewSessionToken();

      const { data } = await fetchSession({
        token,
        country,
        lang
      });

      if (data) {
        store.dispatch({ type: SET_SESSION_DATA, payload: data.session });
        store.dispatch({ type: SET_SESSION_TOKEN, payload: token });
      }
    }

    setCookie('uyn_bearer', token, {
      path: '/',
      expires: moment().add(7, 'days').toDate()
    });

    // se loggato chiamo la /me
    const logged = getCookieLogged();

    if (logged) {
      const { error, data } = await fetchProfileSettings({
        lang,
        country,
        token
      });

      if (error) {
        logoutActions(removeCookie, store);
        setupSession();
      } else {
        store.dispatch({
          type: SET_PROFILE,
          payload: data.user_info
        });
      }
    }
  };

  const ENV = process.env.ENV || process.env.NEXT_PUBLIC_ENV;
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AlertProvider>
          <Layout>
            <AdviseCountry />
            <PopupAlertShipping />
            <Component {...pageProps} />
            {ENV === 'production' && <GoogleTagManagerBody />}
            <ChatWithNoSSR />
          </Layout>
        </AlertProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default UynApp;
