import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import data from '../public/shipping_countries_list.json';
import all_countries from '../public/all_countries.json';
import { useCookies } from 'react-cookie';
import { Button } from './v2/UI/Button';
import { XIcon } from '@heroicons/react/solid';

const PopupAlertShipping = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [error_message, setErrorMessage] = useState('');
  const router = useRouter();
  // const { lang, country } = getLangCountry(router.query);
  const shipping_countries: any = data;
  const [cookies, setCookie] = useCookies([
    'not_deliver_to',
    'uyn_geo_country'
  ]);
  const closePopup = () => {
    setCookie('not_deliver_to', 1, { path: '/' });

    setIsOpen(false);
  };
  useEffect(() => {
    // const uyn_session_country = 'jp'
    const countries = shipping_countries['en'];

    if (cookies.uyn_geo_country && countries && !cookies.not_deliver_to) {
      const isIncluded: boolean = countries.some.call(
        countries,
        (el: any) =>
          el['country-code'].toLowerCase() === cookies.uyn_geo_country
      );

      if (!isIncluded) {
        setIsOpen(true);
        const country_found = all_countries.find(
          (el: any) => el['code'].toLowerCase() === cookies.uyn_geo_country
        );
        const country = country_found ? country_found['name'] : '';
        setErrorMessage(
          t('NOT_DELIVER_TO_MESSAGE').replace('{country}', country)
        );
        console.log(error_message);
      }
    }
  }, [cookies.uyn_geo_country]);

  if (cookies.not_deliver_to) {
    return null;
  }
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={setIsOpen}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-5xl pb-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="div"
                className="flex justify-between items-center text-lg font-medium text-black font-sans bg-modalgrey px-6 py-4"
              >
                <h3 className="text-2xl font-medium text-black font-sans"></h3>

                <button
                  className="text-center bg-white text-black rounded-full w-8 h-8 flex justify-center items-center"
                  onClick={closePopup}
                >
                  <XIcon className="w-4 h-4" />
                </button>
              </Dialog.Title>

              <div className="pt-6 px-6 flex flex-col md:flex-row items-center justify-between">
                <p>{error_message}</p>
              </div>

              <div className="mt-4 flex px-6">
                <Button variant="dark" onClick={closePopup}>
                  {t('NOT_DELIVER_TO_MESSAGE_BUTTON')}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PopupAlertShipping;
