import { ApiCommonParams } from '../models/app';
import { ICart } from '../models/cart';
import { getAPIClient } from '@/lib/api';

export const fetchCart = async ({
  lang,
  country,
  token
}: ApiCommonParams): Promise<ICart | undefined> => {
  const apiClient = getAPIClient({ country });

  try {
    const { data } = await apiClient.get<ICart>('/wp-json/uyn/v1/cart', {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country,
        ['UYN-BEARER']: token
      }
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addProduct = async ({
  sku,
  quantity,
  lang,
  country,
  token
}: ApiCommonParams & {
  sku: string;
  quantity: number;
}): Promise<ICart> => {
  const apiClient = getAPIClient({ country });

  const { data } = await apiClient.post<ICart>(
    '/wp-json/uyn/v1/cart/product',
    { product_sku: sku, product_quantity: quantity },
    {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country,
        ['UYN-BEARER']: token
      }
    }
  );

  return data;
};

export const selectGiftProduct = async ({
  sku,
  promotion_id,
  lang,
  country,
  token
}: ApiCommonParams & {
  sku: string;
  promotion_id: number;
}): Promise<ICart> => {
  const apiClient = getAPIClient({ country });

  const { data } = await apiClient.post<ICart>(
    '/wp-json/uyn/v1/cart/select-gift-product',
    { product_sku: sku, promotion_id: promotion_id },
    {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country,
        ['UYN-BEARER']: token
      }
    }
  );

  return data;
};

export const clearGiftProduct = async ({
  promotion_id,
  lang,
  country,
  token
}: ApiCommonParams & {
  promotion_id: number;
}): Promise<ICart> => {
  const apiClient = getAPIClient({ country });

  const { data } = await apiClient.post<ICart>(
    '/wp-json/uyn/v1/cart/clear-gift-product',
    { promotion_id: promotion_id },
    {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country,
        ['UYN-BEARER']: token
      }
    }
  );

  return data;
};

export const updateProduct = async ({
  sku,
  quantity,
  lang,
  country,
  token
}: ApiCommonParams & {
  sku: string;
  quantity: number;
}): Promise<ICart> => {
  const apiClient = getAPIClient({ country });

  try {
    const { data } = await apiClient.put<ICart>(
      '/wp-json/uyn/v1/cart/product',
      { product_sku: sku, product_quantity: quantity },
      {
        headers: {
          ['UYN-LANGUAGE']: lang,
          ['UYN-COUNTRY']: country,
          ['UYN-BEARER']: token
        }
      }
    );

    return data;
  } catch (error) {
    return {
      cart: {
        products: [],
        total: 0,
        subtotal: 0,
        remaining: 0,
        gift_products: { products: [], banners: null }
      },
      coupons: []
    };
  }
};

export const removeProduct = async ({
  sku,
  lang,
  country,
  token
}: ApiCommonParams & {
  sku: string;
}): Promise<ICart> => {
  const apiClient = getAPIClient({ country });

  const { data } = await apiClient.delete<ICart>(
    '/wp-json/uyn/v1/cart/product',
    {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country,
        ['UYN-BEARER']: token
      },
      params: {
        product_sku: sku
      }
    }
  );

  return data;
};

export const emptyCart = async ({ lang, country, token }: ApiCommonParams) => {
  const apiClient = getAPIClient({ country });

  const response = await apiClient.delete<ICart>('/wp-json/uyn/v1/cart', {
    headers: {
      ['UYN-LANGUAGE']: lang,
      ['UYN-COUNTRY']: country,
      ['UYN-BEARER']: token
    }
  });

  return response;
};
