import { IChangePassword, IProfileSettings } from '../models/profile';
import { ApiCommonParams, ResponseWithError } from '../models/app';
import { getAPIClient } from '@/lib/api';

export const fetchOrder = async ({
  lang,
  country,
  token,
  id_order
}: {
  lang: string;
  country: string;
  token: string;
  id_order: number | string;
}): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.get('/wp-json/uyn/v1/user/order', {
      headers: {
        ['UYN-BEARER']: token,
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country
      },
      params: {
        id_order
      }
    });

    return response;
  } catch (error) {
    return { error };
  }
};

export const fetchOrderReturn = async ({
  lang,
  country,
  token,
  id_order
}: {
  lang: string;
  country: string;
  token: string;
  id_order: number | string;
}): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.get('/wp-json/uyn/v1/user/order-return', {
      headers: {
        ['UYN-BEARER']: token,
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country
      },
      params: {
        id_order
      }
    });

    return response;
  } catch (error) {
    return { error };
  }
};

export const fetchOrders = async ({
  lang,
  country,
  token
}: ApiCommonParams): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.get('/wp-json/uyn/v1/user/orders', {
      headers: {
        ['UYN-BEARER']: token,
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country
      }
    });

    return response;
  } catch (error) {
    return { error };
  }
};

export const fetchReturns = async ({
  lang,
  country,
  token
}: ApiCommonParams): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.get('/wp-json/uyn/v1/user/returns', {
      headers: {
        ['UYN-BEARER']: token,
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country
      }
    });

    return response;
  } catch (error) {
    return { error };
  }
};

export const fetchProfileSettings = async ({
  lang,
  country,
  token
}: ApiCommonParams): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.get('/wp-json/uyn/v1/user', {
      headers: {
        ['UYN-BEARER']: token,
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country
      }
    });

    const { data } = response;

    if (data && data.error) {
      throw response;
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const saveProfileSettings = async ({
  lang,
  country,
  token,
  settings
}: {
  lang: string;
  country: string;
  token: string;
  settings: IProfileSettings;
}): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.put(
      '/wp-json/uyn/v1/user',
      { user_info: settings },
      {
        headers: {
          ['UYN-BEARER']: token,
          ['UYN-LANGUAGE']: lang,
          ['UYN-COUNTRY']: country
        }
      }
    );

    return response;
  } catch (error) {
    return { error };
  }
};

export const changePassword = async ({
  lang,
  country,
  token,
  password
}: {
  lang: string;
  country: string;
  token: string;
  password: IChangePassword;
}): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.post(
      '/wp-json/uyn/v1/user/change-password',
      {
        old_password: password.old_password,
        new_password: password.new_password
      },
      {
        headers: {
          ['UYN-BEARER']: token,
          ['UYN-LANGUAGE']: lang,
          ['UYN-COUNTRY']: country
        }
      }
    );

    return response;
  } catch (error) {
    return { error };
  }
};

export const requestOrderReturn = async ({
  lang,
  country,
  token,
  id_order,
  message
}: ApiCommonParams & {
  id_order: string;
  message: string;
}): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  return await apiClient.post(
    '/wp-json/uyn/v1/user/order/return',
    { id_order, message },
    {
      headers: {
        ['UYN-BEARER']: token,
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country
      }
    }
  );
};

export const sendWithdrawals = async ({
  lang,
  country,
  token,
  isLoggedIn,
  order_id,
  email,
  items,
  notes,
  pickup_form
}: ApiCommonParams & {
  isLoggedIn: boolean;
  order_id: number;
  email: string;
  items: {
    sku: string;
    quantity?: number;
    message?: string;
    replace_with?: string;
    return_type?: string;
  }[];
  notes: string;
  pickup_form: any;
}): Promise<any> => {
  const apiClient = getAPIClient({ country });

  const URL_LOGGED_USER = '/wp-json/uyn/v1/return';
  const URL_ANONYMOUS_USER = '/wp-json/uyn/v1/return/guest';

  await apiClient.post(
    isLoggedIn ? URL_LOGGED_USER : URL_ANONYMOUS_USER,
    { order_id, email, message: notes, items, pickup_form },
    {
      headers: {
        ['UYN-BEARER']: token,
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country
      }
    }
  );
};
