import { useRouter } from 'next/router';
import { fetchShippingCountriesList } from '../fetchers/shipping';
import { getLangCountry } from '../helpers/languages';
import { getCurrencySymbol } from '../helpers/prices';

export default function usePrice() {
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);
  const countries = fetchShippingCountriesList({ lang });

  const formatPrice = (price: number | string = 0) => {
    const symbol = getCurrencySymbol(countries, country);
    return ` ${symbol}${Number(price).toFixed(2)}`;
  };

  return [formatPrice];
}
