import isEmpty from 'lodash/isEmpty';
import { usePopper } from 'react-popper';
import { Popover, Tab } from '@headlessui/react';
import React, { Fragment, PropsWithChildren, useState } from 'react';

import Collabs from './Collabs';
import Highlights from './Highlights';
import MenuList from './MenuList';
import Previews from './Previews';

import { MenuItem } from '@/types/Menu';

interface Props extends PropsWithChildren {
  item: MenuItem;
}

const MenuPopover: React.FC<Props> = ({ item, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [popperElement, setPopperElement] = useState<any>();
  const [referenceElement, setReferenceElement] = useState<any>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed'
  });

  function toggleOpen() {
    setIsOpen(isOpen => !isOpen);
  }

  const hasTabs = Boolean(item.tabs);
  const hasHighlights = !isEmpty(item.highlights);
  const hasCollabs = !isEmpty(item.collabs);
  const hasPreviews = !isEmpty(item.previews);

  return (
    <Popover className="relative z-50" onMouseLeave={() => setIsOpen(false)}>
      <Popover.Button
        ref={setReferenceElement}
        onMouseEnter={() => setIsOpen(true)}
        className="outline-none"
      >
        {children}
      </Popover.Button>

      {isOpen && (
        <Popover.Panel
          static
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className="flex w-screen bg-white border border-t-gray-100">
            {hasTabs ? (
              <Tab.Group>
                <Tab.List className="min-h-full flex flex-col">
                  {item.children?.map(item => (
                    <Tab
                      key={item.name}
                      className="bg-white font-bold uppercase tracking-uyn py-6 px-20 ui-selected:bg-gray-200 outline-none"
                    >
                      {item.name}
                    </Tab>
                  ))}
                </Tab.List>

                <Tab.Panels className="w-full">
                  {item.children?.map(item => (
                    <Tab.Panel
                      key={item.name}
                      className="h-full flex bg-gray-200"
                    >
                      <MenuList
                        key={item.name}
                        item={item}
                        toggleOpen={toggleOpen}
                      />

                      {hasPreviews && (
                        <Previews item={item} toggleOpen={toggleOpen} />
                      )}
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            ) : (
              <Fragment>
                {(hasHighlights || hasCollabs) && (
                  <div className="min-h-full flex flex-col space-y-12 bg-gray-200 px-8 py-10 2xl:min-w-[14vw]">
                    {hasHighlights && (
                      <Highlights item={item} toggleOpen={toggleOpen} />
                    )}
                    {hasCollabs && (
                      <Collabs item={item} toggleOpen={toggleOpen} />
                    )}
                  </div>
                )}

                <MenuList key={item.name} item={item} toggleOpen={toggleOpen} />

                {hasPreviews && (
                  <Previews item={item} toggleOpen={toggleOpen} />
                )}
              </Fragment>
            )}
          </div>
        </Popover.Panel>
      )}
    </Popover>
  );
};

export default MenuPopover;
