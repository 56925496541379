import { ResponseWithError } from '../models/app';
import { getAPIClient } from '@/lib/api';
import axios from 'axios';

export const newsletterSignup = async ({
  token,
  email,
  lang,
  country
}: {
  token: string;
  email: string;
  lang: string;
  country: string;
}): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.post(
      '/wp-json/uyn/v1/newsletter',
      { email },
      {
        headers: {
          ['UYN-LANGUAGE']: lang,
          ['UYN-COUNTRY']: country,
          ['UYN-BEARER']: token
        }
      }
    );

    return response;
  } catch (error) {
    return { error };
  }
};

export const getNewsletterOffer = async ({
  token,
  lang,
  country
}: {
  token: string;
  lang: string;
  country: string;
}): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.get('/wp-json/uyn/v1/popup-info', {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country,
        ['UYN-BEARER']: token
      },
      params: {
        popup: 'newsletter'
      }
    });

    return response;
  } catch (error) {
    return { error };
  }
};

export const newsletterSignupLanding = async ({
  name,
  token,
  email,
  lang,
  country
}: {
  name: string;
  token: string;
  email: string;
  lang: string;
  country: string;
}): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.post(
      '/wp-json/uyn/v1/newsletter/popup',
      { email, name },
      {
        headers: {
          ['UYN-LANGUAGE']: lang,
          ['UYN-COUNTRY']: country,
          ['UYN-BEARER']: token
        }
      }
    );

    return response;
  } catch (error) {
    return { error };
  }
};

export const newsletterSignupZKS = async ({
  name,
  surname,
  address,
  city,
  zipcode,
  region,
  token,
  email,
  lang,
  country,
  size
}: {
  name: string;
  surname: string;
  address: string;
  city: string;
  zipcode: string;
  region: string;
  token: string;
  email: string;
  lang: string;
  country: string;
  size: string;
}): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.post(
      '/wp-json/uyn/v1/newsletter/zks',
      { email, name, surname, address, city, zipcode, region, size },
      {
        headers: {
          ['UYN-LANGUAGE']: lang,
          ['UYN-COUNTRY']: country,
          ['UYN-BEARER']: token
        }
      }
    );

    return response;
  } catch (error) {
    return { error };
  }
};

export const newsletterSignupFull = async ({
  name,
  surname,
  token,
  email,
  lang,
  country
}: {
  name: string;
  surname: string;
  token: string;
  email: string;
  lang: string;
  country: string;
}): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.post(
      '/wp-json/uyn/v1/newsletter/full',
      { email, name, surname },
      {
        headers: {
          ['UYN-LANGUAGE']: lang,
          ['UYN-COUNTRY']: country,
          ['UYN-BEARER']: token
        }
      }
    );

    return response;
  } catch (error) {
    return { error };
  }
};

export const quizLanding = async ({
  token,
  email,
  lang,
  country,
  name,
  surname,
  quiz_1,
  quiz_2,
  quiz_3
}: {
  name: string;
  surname: string;
  token: string;
  email: string;
  lang: string;
  country: string;
  quiz_1: string;
  quiz_2: string;
  quiz_3: string;
}): Promise<ResponseWithError> => {
  try {
    const url = `${process.env.NEXT_PUBLIC_UYN_BASE_URL}/advanced-api/api/quiz/quiz-landing`;
    const response = await axios.post(
      url,
      {
        email,
        first_name: name,
        last_name: surname,
        answers: {
          quiz_1,
          quiz_2,
          quiz_3
        }
      },
      {
        headers: {
          ['UYN-LANGUAGE']: lang,
          ['UYN-COUNTRY']: country,
          ['UYN-BEARER']: token
        }
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    return { error };
  }
};
