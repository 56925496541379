import moment from 'moment';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';

import { ChevronRightIcon, XIcon } from '@heroicons/react/solid';

import Media from './Media';
import { Button, buttonVariants } from './UI/Button';

import { usePopup } from '@/hooks/popups/use-popup';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

const PopupShippingUs = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: popup, isLoading } = usePopup();
  const [cookies, setCookies] = useCookies(['uyn_popup']);
  const { t } = useTranslation();

  function closePopup() {
    setCookies('uyn_popup', true, {
      expires: moment().add(14, 'days').toDate()
    });

    setIsOpen(false);
  }

  return (
    <>
      <svg
        onClick={() => {
          setIsOpen(true);
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-5 h-5 cursor-pointer"
      >
        <path
          fillRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
          clipRule="evenodd"
        />
      </svg>

      <Dialog open={isOpen} onClose={closePopup} className="relative z-70">
        <Dialog.Backdrop className="fixed inset-0 bg-black/30 " />

        <div className="fixed inset-0 flex items-center justify-center px-6 ">
          <Dialog.Panel className="w-full max-w-4xl bg-black rounded overflow-hidden shadow-xl">
            <div className="order-first flex flex-col bg-white text-black p-6">
              <div className="flex-1 flex flex-col justify-center space-y-6 p-3 md:p-4">
                <div className="space-y-6">
                  <p className="text-black">{t('US_SHIPPING.POPUP_TEXT')}</p>
                  <Button
                    onClick={closePopup}
                    aria-label={t('GO_TO_CHECKOUT')}
                    className={buttonVariants({ variant: 'dark' })}
                  >
                    {t('US_SHIPPING.BTN_TEXT')}
                  </Button>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default PopupShippingUs;
