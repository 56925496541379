import React from 'react';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
// @ts-ignore
import ReactCountryFlag from 'react-country-flag';
import { classNames } from '../helpers/classes';
import { ICountryListItem } from '../models/footer';
import { getCountryName, getFlagCode } from '../helpers/countries';
import { fetchShippingCountriesList } from '../fetchers/shipping';
import { useRouter } from 'next/router';
import { getLangCountry } from '../helpers/languages';

export const Flag = ({ country }: { country: string }) => {
  return (
    <ReactCountryFlag
      countryCode={country}
      alt={country}
      svg
      style={{
        width: '2em',
        height: '2em',
        borderRadius: '100%',
        objectFit: 'cover'
      }}
    />
  );
};

const SelectCountry = ({
  country,
  currentShippingCountry,
  onChange
}: {
  country: string;
  currentShippingCountry: ICountryListItem | null;
  onChange: (value: string) => void;
}) => {
  const router = useRouter();
  const { lang } = getLangCountry(router.query);
  const countries = fetchShippingCountriesList({ lang });

  countries.sort(function (a, b) {
    if (a['country-name']! < b['country-name']!) {
      return -1;
    }

    if (a['country-name']! > b['country-name']!) {
      return 1;
    }

    return 0;
  });

  return (
    <Listbox value={country} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full border border-gray-300 rounded-md shadow-sm pl-2 pr-2 py-1 text-left cursor-default focus:outline-none sm:text-sm">
              <span className="flex items-center">
                <Flag country={getFlagCode(currentShippingCountry)} />
                <span className="ml-2 block truncate text-black">
                  {getCountryName(currentShippingCountry)}
                </span>
              </span>
              <span className="ml-2 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-black/30"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-40 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {countries.map(c => {
                  const countryCode = c['country-code'];
                  const countryName = c['country-name'];

                  return (
                    <Listbox.Option
                      key={countryCode}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-gray-600' : 'text-gray-900',
                          'cursor-default select-none relative py-1 pl-2 pr-2'
                        )
                      }
                      value={countryCode}
                    >
                      {({ selected }) => (
                        <>
                          <div className="flex items-center">
                            <Flag country={countryCode || ''} />
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'ml-2 block truncate'
                              )}
                            >
                              {countryName}
                            </span>
                          </div>
                        </>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SelectCountry;
