import React from 'react';

const GoogleTagManagerBody = () => {
  return (
    <noscript>
      <iframe
        title="gtm"
        src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_GTM}`}
        height="0"
        width="0"
        style={{
          display: 'none',
          visibility: 'hidden'
        }}
      ></iframe>
    </noscript>
  );
};

export default GoogleTagManagerBody;
