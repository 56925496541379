import { ApiCommonParams, ResponseWithError } from '../models/app';
import { getAPIClient } from '@/lib/api';

export const createSession = async ({
  country
}: {
  country: string;
}): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.post(
      '/advanced-api/api/session',
      {},
      {
        headers: {
          ['UYN-COUNTRY']: country
        }
      }
    );

    return response;
  } catch (error) {
    return { error };
  }
};

export const fetchSession = async ({
  token,
  country,
  lang
}: ApiCommonParams): Promise<ResponseWithError> => {
  const apiClient = getAPIClient({ country });

  try {
    if (!token) {
      throw { error: 'No token' };
    }

    const response = await apiClient.get('/advanced-api/api/session', {
      headers: {
        ['UYN-BEARER']: token,
        ['UYN-COUNTRY']: country,
        ['UYN-LANGUAGE']: lang
      }
    });

    return response;
  } catch (error) {
    return { error };
  }
};
