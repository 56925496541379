import { useCookies } from 'react-cookie';

export const isLogged = () => {
  const [cookies] = useCookies(['uyn_authenticated']);
  return cookies['uyn_authenticated'] === 'true';
};

export const getToken = () => {
  const [cookies] = useCookies(['uyn_bearer']);
  return cookies['uyn_bearer'];
};
