export const SET_SESSION_DATA = 'SET_SESSION_DATA';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN';
export const SET_FOOTER_DATA = 'SET_FOOTER_DATA';
export const SET_MAPPING_COLORS = 'SET_MAPPING_COLORS';
export const SET_MENU = 'SET_MENU';
export const SET_SHOP_PRICES = 'SET_SHOP_PRICES';
export const SET_PRICE_LIST = 'SET_PRICE_LIST';
export const SET_STOCK_LIST = 'SET_STOCK_LIST';
export const SET_SESSION_CART = 'SET_SESSION_CART';
export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST';
export const SET_HOME = 'SET_HOME';
export const CLEAR_STATE = 'CLEAR_STATE';

export type SET_SESSION_DATA_TYPE = 'SET_SESSION_DATA';
export type SET_PROFILE_TYPE = 'SET_PROFILE';
export type SET_CATEGORIES_TYPE = 'SET_CATEGORIES';
export type SET_SESSION_TOKEN_TYPE = 'SET_SESSION_TOKEN';
export type SET_FOOTER_DATA_TYPE = 'SET_FOOTER_DATA';
export type SET_MAPPING_COLORS_TYPE = 'SET_MAPPING_COLORS';
export type SET_MENU_TYPE = 'SET_MENU';
export type SET_SHOP_PRICES_TYPE = 'SET_SHOP_PRICES';
export type SET_PRICE_LIST_TYPE = 'SET_PRICE_LIST';
export type SET_STOCK_LIST_TYPE = 'SET_STOCK_LIST';
export type SET_SESSION_CART_TYPE = 'SET_SESSION_CART';
export type SET_COUNTRIES_LIST_TYPE = 'SET_COUNTRIES_LIST';
export type SET_HOME_TYPE = 'SET_HOME_TYPE';
export type CLEAR_STATE_TYPE = 'CLEAR_STATE_TYPE';
