import {
  SET_SESSION_DATA,
  SET_SESSION_DATA_TYPE,
  SET_PROFILE,
  SET_PROFILE_TYPE,
  SET_CATEGORIES,
  SET_SESSION_TOKEN,
  SET_SESSION_TOKEN_TYPE,
  SET_SESSION_CART_TYPE,
  SET_SESSION_CART,
  CLEAR_STATE,
  CLEAR_STATE_TYPE
} from './types';

import { ICategory } from '../models';
import { ISession } from '../models/session';
import { ICart } from '../models/cart';
import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { IProfileSettings } from '../models/profile';

const INITIAL_GLOBAL_STATE = {
  session: undefined,
  profile: undefined
};
export interface IGlobalState {
  session: ISession | undefined;
  profile: IProfileSettings | undefined;
}

export type Action =
  | { type: SET_SESSION_DATA_TYPE; payload: ISession }
  | { type: SET_PROFILE_TYPE; payload: IProfileSettings }
  | { type: SET_SESSION_TOKEN_TYPE; payload: string }
  | { type: SET_SESSION_CART_TYPE; payload: ICart }
  | { type: CLEAR_STATE_TYPE };

const reducer = (
  state: IGlobalState | any,
  action: AnyAction
): IGlobalState => {
  switch (action.type) {
    case HYDRATE:
      // Attention! This will overwrite client state! Real apps should use proper reconciliation.
      return { ...state, ...action.payload };
    case SET_SESSION_DATA:
      return { ...state, session: action.payload as ISession };
    case SET_PROFILE:
      return { ...state, profile: action.payload as IProfileSettings };
    case SET_CATEGORIES:
      return { ...state, categories: action.payload as ICategory[] };
    case SET_SESSION_TOKEN:
      return {
        ...state,
        session: { ...state.session, token: action.payload as string }
      };
    case SET_SESSION_CART:
      return {
        ...state,
        session: {
          ...state.session,
          cart: action.payload.cart,
          coupons: action.payload.coupons
        }
      };
    case CLEAR_STATE:
      return INITIAL_GLOBAL_STATE;
    default:
      return state;
  }
};

export default reducer;
