import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  error?: string;
}

const Input: React.FC<Props> = ({
  label,
  name,
  error,
  required,
  className,
  ...rest
}) => {
  return (
    <div className="w-full flex flex-col space-y-1">
      {label && (
        <label htmlFor={name} className="text-sm md:text-base">
          <span>{label}</span>
          {required && <span className="text-red-500"> *</span>}
        </label>
      )}

      <input
        id={name}
        name={name}
        className={twMerge(
          'w-full border border-gray-400 rounded-md p-2 focus:ring-black focus:border-black md:p-3',
          className
        )}
        {...rest}
      />

      {error && <span className="text-xs text-red-500">{error}</span>}
    </div>
  );
};

export default Input;
