export enum BannerType {
  GENERIC = 'banner_generic'
}

export type Banner = {
  lang: string;
  text: string;
  cta_text: string;
  cta_link: string;
};
