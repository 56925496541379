import React from 'react';

import { MenuItem } from '@/types/Menu';
import LinkTo from '@/components/LinkTo';
import clsx from 'clsx';

interface Props {
  item: MenuItem;
  toggleOpen: () => void;
}

const Highlights: React.FC<Props> = ({ item, toggleOpen }) => {
  console.log(item.highlights);
  return (
    <ul className="space-y-3">
      {item.highlights?.map((highlight, idx) => (
        <li key={idx}>
          {highlight.href && (
            <LinkTo
              href={highlight.href}
              onClick={toggleOpen}
              className={
                highlight.extraClass?.length
                  ? clsx('font-bold', highlight.extraClass)
                  : 'font-bold'
              }
              aria-label={highlight.name}
            >
              {highlight.name}
            </LinkTo>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Highlights;
