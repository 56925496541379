import axios from 'axios';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';

import { getLangCountry } from '@/helpers/languages';

import { Popup } from '@/types/Popup';

export function usePopup() {
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);

  return useQuery(['popup'], async () => {
    const urlPopups =
      country == 'us' ? '/popups/popups_us.json' : '/popups/popups.json';
    const { data } = await axios.get<Popup[]>(urlPopups);

    return (
      data.find(
        popup =>
          Array.isArray(popup.storeview) &&
          popup.storeview.includes(country) &&
          popup.lang === lang
      ) ?? null
    );
  });
}
