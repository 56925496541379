import axios from 'axios';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';

import { getLangCountry } from '@/helpers/languages';

import { Banner, BannerType } from '@/types/Banner';

interface Banners {
  [BannerType.GENERIC]?: Banner[];
}

export function useBanner(bannerType: BannerType) {
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);

  return useQuery(['banners', bannerType], async () => {
    const urlBanners =
      country == 'us' ? '/banners/banners_us.json' : '/banners/banners.json';
    const { data } = await axios.get<Banners>(urlBanners);

    return data[bannerType]?.find(banner => banner.lang === lang) ?? null;
  });
}
