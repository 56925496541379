import { useRouter } from 'next/router';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import { useCallback } from 'react';
import { ParsedUrlQuery } from 'querystring';

export default function useQueryParams(): [
  ParsedUrlQuery,
  (value: Record<string, string | string[] | undefined>) => void
] {
  const router = useRouter();
  const query = router.query;

  const setQuery = useCallback(
    (values: Record<string, string | string[] | undefined>) => {
      // Rimuovo i params delle route ritornati in "query"
      const currentQuery = omit(query, [
        'lang',
        'country',
        'slug',
        'categories'
      ]);

      router.replace(
        {
          pathname: window.location.pathname,
          query: omitBy({ ...currentQuery, ...values }, isUndefined)
        },
        undefined,
        { shallow: true }
      );
    },
    [router]
  );

  return [query, setQuery];
}
