import React, { Fragment, useEffect, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { MenuAlt2Icon } from '@heroicons/react/outline';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XIcon
} from '@heroicons/react/solid';
import Logo from '../Logo';

import { isEmpty, last } from 'lodash';
import clsx from 'clsx';
import { useMediaQuery } from 'usehooks-ts';
import ChangeLangCountry from '@/components/ChangeLangCountry';
import Highlights from './Highlights';
import Collabs from './Collabs';
import { MenuItem } from '@/types/Menu';
import LinkTo from '@/components/LinkTo';
import { useTranslation } from 'react-i18next';

interface Props {
  menu: MenuItem[];
}

const Menu: React.FC<Props> = ({ menu }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 1280px)');

  const initialState = {
    children: menu.map(child => ({
      ...child,
      children: child.children
    }))
  };

  const [currentItem, setCurrentItem] = useState<MenuItem>(initialState);
  const [previousItems, setPreviousItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    setCurrentItem(initialState);
  }, [menu]);

  useEffect(() => {
    if (isDesktop) {
      setIsOpen(false);
    }
  }, [isDesktop]);

  function toggleOpen() {
    setIsOpen(isOpen => !isOpen);
  }

  function handleItemClick(item: MenuItem) {
    setCurrentItem(item);
    setPreviousItems(previousItems => [...previousItems, currentItem]);
  }

  function handleBackClick() {
    const lastPushedItem = last(previousItems);

    if (lastPushedItem) {
      setCurrentItem(lastPushedItem);
      setPreviousItems(previousItems => previousItems.slice(0, -1));
    }
  }

  const previousItem = last(previousItems);

  const hasHighlights = !isEmpty(currentItem.highlights);
  const hasCollabs = !isEmpty(currentItem.collabs);

  return (
    <div className="items-center flex xl:hidden">
      <button onClick={toggleOpen} className="w-min h-min">
        <MenuAlt2Icon className="w-6 h-6" />
      </button>

      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          onClose={() => {}}
          className="fixed z-20 inset-0 xl:hidden overflow-y-auto"
        >
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="flex flex-col min-h-screen h-auto bg-white">
              <div className="relative min-h-[62px] flex items-center border-b px-6 py-3 sm:px-12">
                {previousItem && (
                  <button
                    onClick={handleBackClick}
                    className="flex justify-center items-center space-x-1.5 absolute left-4 sm:left-12"
                  >
                    <ChevronLeftIcon className="w-8 h-8" />

                    <span className="text-menu-body font-bold uppercase">
                      {currentItem.name}
                    </span>
                  </button>
                )}

                {!previousItem && (
                  <Logo containerClassName="h-[35px] lg:hidden" />
                )}

                <button
                  onClick={toggleOpen}
                  className="absolute right-4 sm:right-12"
                >
                  <XIcon className="w-6 h-6" />
                </button>
              </div>

              <Transition.Child
                key={Math.random()}
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom={clsx({
                  'translate-x-full': previousItem,
                  '-translate-x-full': !previousItem
                })}
                enterTo="translate-x-0"
              >
                <div className="flex-1 flex flex-col">
                  <ul className="my-3 overflow-y-auto">
                    {currentItem.children?.map((item, idx) => {
                      const hasChildren = !isEmpty(item.children);
                      const hasHref = !isEmpty(item.href);
                      const hasName = Boolean(item.name);

                      return (
                        <li key={idx}>
                          {!hasName && hasChildren ? (
                            <ul>
                              {item.children?.map((item, idx) => (
                                <LinkTo
                                  key={idx}
                                  href={item.href!}
                                  aria-label={item.name}
                                  onClick={toggleOpen}
                                  className={clsx(
                                    'inline-block w-full text-menu-body uppercase px-6 py-3 sm:px-12 active:bg-gray-100',
                                    { 'font-bold': item.highlight }
                                  )}
                                >
                                  {item.name}
                                </LinkTo>
                              ))}
                            </ul>
                          ) : hasChildren ? (
                            <button
                              key={idx}
                              onClick={() => handleItemClick(item)}
                              className={clsx(
                                'w-full flex justify-between items-center text-menu-body uppercase px-6 py-3 sm:px-12 active:bg-gray-100',
                                { 'font-bold': item.highlight }
                              )}
                            >
                              <span>{item.name}</span>

                              <ChevronRightIcon className="h-5 w-5" />
                            </button>
                          ) : hasHref ? (
                            <LinkTo
                              href={item.href!}
                              aria-label={item.name}
                              onClick={toggleOpen}
                              className={clsx(
                                'inline-block w-full text-menu-body uppercase px-6 py-3 sm:px-12 active:bg-gray-100',
                                { 'font-bold': item.highlight }
                              )}
                            >
                              {item.name}
                            </LinkTo>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>

                  {!previousItem ? (
                    <Fragment>
                      <ul className="flex-1 space-y-3 p-6 sm:px-12 bg-gray-100">
                        <li className="text-xl">
                          <LinkTo
                            href="/profile"
                            aria-label={t('MENU.MY_PROFILE')}
                          >
                            {t('MENU.MY_PROFILE')}
                          </LinkTo>
                        </li>

                        <li className="text-xl">
                          <LinkTo
                            href="/store-locator"
                            aria-label={t('MENU.SHOP_FINDER')}
                          >
                            {t('MENU.SHOP_FINDER')}
                          </LinkTo>
                        </li>

                        <li className="text-xl">
                          <LinkTo href="/contacts" aria-label={t('MENU.HELP')}>
                            {t('MENU.HELP')}
                          </LinkTo>
                        </li>
                      </ul>

                      <ChangeLangCountry
                        hideChevron
                        buttonClassName="w-full flex items-center px-6 py-3 sm:px-12"
                      />
                    </Fragment>
                  ) : (
                    (hasHighlights || hasCollabs) && (
                      <div className="h-full flex-1 flex flex-col space-y-12 bg-gray-100 px-6 py-6 sm:px-12">
                        {hasHighlights && (
                          <Highlights
                            item={currentItem}
                            toggleOpen={toggleOpen}
                          />
                        )}
                        {hasCollabs && (
                          <Collabs item={currentItem} toggleOpen={toggleOpen} />
                        )}
                      </div>
                    )
                  )}
                </div>
              </Transition.Child>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Menu;
