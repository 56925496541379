import { getAPIClient } from '@/lib/api';
import { DEFAULT_LANG, STRING_LANG } from '../helpers/languages';
import { ICountryListItem } from '../models/footer';
import shipping_countries_list from '../public/shipping_countries_list.json';
import shipping_rates from '../public/shipping/shipping_rates.json';

export const getShippingRate = async ({
  country,
  lang
}: {
  country: string;
  lang: string;
}): Promise<any> => {
  const apiClient = getAPIClient({ country });

  try {
    const response = await apiClient.get('/wp-json/uyn/v1/shipping/cost', {
      params: { country },
      headers: { 'UYN-LANGUAGE': lang, ['UYN-COUNTRY']: country }
    });

    return response;
  } catch (error) {
    return { error };
  }
};

export const fetchShippingCountriesList = ({
  lang = DEFAULT_LANG
}: {
  lang: STRING_LANG;
}): ICountryListItem[] => {
  return shipping_countries_list[lang] || [];
};

export const fetchShippingCost = async (
  country: string
): Promise<{ cost: number; free: number } | undefined> => {
  return (shipping_rates as any)[country];
};
