import React, { PropsWithChildren } from 'react';

import Header from './v2/Layout/Header';
import Footer from './v2/Layout/Footer';
import GenericBanner from './v2/GenericBanner';
import Popup from './v2/Popup';

interface Props extends PropsWithChildren {}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />

      <main className="flex-1">
        <GenericBanner />
        {children}
        <Popup />
      </main>

      <Footer />
    </div>
  );
};

export default Layout;
