import axios from 'axios';
import { lowerCase } from 'lodash';

interface GetAPIClientParams {
  country?: string;
}

export function getAPIClient(params?: GetAPIClientParams) {
  const isUS = lowerCase(params?.country) === 'us';

  return axios.create({
    baseURL: isUS
      ? process.env.NEXT_PUBLIC_UYN_BASE_URL_US
      : process.env.NEXT_PUBLIC_UYN_BASE_URL
  });
}
