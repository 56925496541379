// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL): void => {
  if (!window || typeof window.gtag !== 'function') {
    return;
  }

  window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_GTM, {
    page_path: url
  });
};

type GTagEvent = {
  action: string;
  category?: string;
  label?: string;
  value?: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action,
  category = '',
  label = '',
  value = 0
}: GTagEvent): void => {
  if (!window || typeof window.gtag !== 'function') {
    return;
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value
  });
};
