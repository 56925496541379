import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/solid';
import {
  fetchAdvisedUrl,
  getGeolocationCountry_v2_fe
} from '../fetchers/geolocation';
import { useRouter } from 'next/router';
import { getLangCountry } from '../helpers/languages';
import { useCookies } from 'react-cookie';
import { fetchShippingCountriesList } from '../fetchers/shipping';
import { buttonVariants } from './v2/UI/Button';
import { useSessionStorage } from 'usehooks-ts';

const AdviseCountry = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [translatedUrl, setTranslatedUrl] = useState('');
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);
  const [cookies, setCookie] = useCookies([
    'uyn_bearer',
    'uyn_actual_country',
    'uyn_country',
    'uyn_geo_country'
  ]);
  const shippingCountriesList = fetchShippingCountriesList({ lang });
  const [geoCountry, setGeoCountry] = useSessionStorage('uyn_geo_country', ''); // è il campo 'country-code' della risposta 'geolocation' non la storeview

  useEffect(() => {
    checkCountryGeolocation();
  }, []);

  useEffect(() => {
    if (router.isReady) {
      getTranslation();
    }
  }, [geoCountry, router.isReady]);

  const geoStoreView = () => {
    if (!geoCountry) return '';

    const country = shippingCountriesList.find(
      c => c['country-code']?.toLowerCase() === geoCountry.toLowerCase()
    );

    return country ? country.storeview : '';
  };

  const getTranslation = async () => {
    const storeview = geoStoreView();

    if (!country || !geoCountry || country === storeview) {
      return;
    }

    const shownAdvice =
      sessionStorage.getItem('advise_country_shown') === 'true';

    if (shownAdvice) {
      return;
    }

    const isCategoryPage = router.asPath.includes('shop');
    const isProductPage = !!router.query.slug;

    if (isCategoryPage || isProductPage) {
      const url = isCategoryPage
        ? `/${(router.query.categories as string[])?.join('/')}`
        : `/${router.query.slug}`;

      const data = await fetchAdvisedUrl({ url, lang, country });

      if (!Array.isArray(data.translations)) {
        setTranslatedUrl(
          `/${lang}_${storeview}${data.type === 'category' ? '/shop' : '/'}` +
            data.translations[lang]
        );

        setIsOpen(true);
      }
    } else {
      setTranslatedUrl(
        router.asPath.replace(String(router.query.lang), `${lang}_${storeview}`)
      );

      setIsOpen(true);
    }
  };

  const checkCountryGeolocation = async () => {
    if (cookies.uyn_geo_country) {
      return;
    }

    const newGeoCountry = await getGeolocationCountry_v2_fe();

    setCookie('uyn_geo_country', newGeoCountry, {
      path: '/'
    });

    setGeoCountry(newGeoCountry);
  };

  const onCancel = () => {
    setIsOpen(false);
    sessionStorage.setItem('advise_country_shown', 'true');

    const actualCountry = shippingCountriesList.find(
      c => c['country-code']?.toLowerCase() === country.toLowerCase()
    );

    if (actualCountry) {
      setCookie(
        'uyn_actual_country',
        actualCountry['country-code']?.toLowerCase(),
        {
          path: '/'
        }
      );
    }
  };

  const onChangeConfirm = async () => {
    const actualCountry = shippingCountriesList.find(
      c => c['country-code']?.toLowerCase() === String(geoCountry).toLowerCase()
    );

    if (actualCountry) {
      setCookie(
        'uyn_actual_country',
        actualCountry['country-code']?.toLowerCase(),
        {
          path: '/'
        }
      );
    }

    setCookie('uyn_bearer', '', { path: '/' });
    setCookie('uyn_country', geoStoreView(), {
      path: '/'
    });

    window.location.href = translatedUrl;

    setIsOpen(false);
  };

  const getCountryName = () => {
    const country = shippingCountriesList.find(
      c => c['country-code']?.toLowerCase() === geoCountry.toLowerCase()
    );

    return country ? country['country-name'] : '';
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onCancel}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-5xl pb-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-uyn">
              <Dialog.Title
                as="div"
                className="flex justify-between items-center text-lg font-medium text-black  bg-sand-100 px-6 py-4"
              >
                <div className="h5 text-black ">
                  {t('FROM')} {getCountryName()}?
                </div>

                <button
                  className="text-center bg-white text-black rounded-full w-8 h-8 flex justify-center items-center"
                  onClick={onCancel}
                >
                  <XIcon className="w-4 h-4" />
                </button>
              </Dialog.Title>
              <div className="pt-6 px-6 flex flex-col md:flex-row items-center justify-between">
                <p>{t('COUNTRY_WEBSITE')}</p>
              </div>
              <div className="mt-4 flex px-6 space-x-2">
                <a
                  onClick={onChangeConfirm}
                  className={buttonVariants({ variant: 'dark' })}
                >
                  {t('YES_GO')} {getCountryName()}
                </a>
                <a
                  onClick={onCancel}
                  className={buttonVariants({ variant: 'light' })}
                >
                  {t('NO_STAY')}
                </a>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AdviseCountry;
