import React from 'react';
import dynamic from 'next/dynamic';

import Logo from './Logo';
import Menu from './Menu';
import MenuPopover from './Menu/MenuPopover';
import MenuCart from '@/components/Menu/MenuCart';
import MenuSearch from '@/components/Menu/MenuSearch';

import { useMenu } from '@/hooks/layout/use-menu';

const Account = dynamic(import('./Account'), { ssr: false });

const Navbar = () => {
  const { data: menu = [] } = useMenu();

  return (
    <section className="section py-3 xl:py-0">
      <div className="flex items-center">
        <div className="flex-1 flex gap-4">
          <Menu menu={menu} />

          <Logo containerClassName="h-[35px] lg:hidden" />

          <div className="hidden xl:flex">
            {menu.slice(0, 5).map(item => (
              <MenuPopover key={item.name} item={item}>
                <span className="block text-menu-body font-bold uppercase p-6 border-2 border-transparent hover:border-b-black">
                  {item.name}
                </span>
              </MenuPopover>
            ))}
          </div>
        </div>

        <Logo containerClassName="h-auto hidden lg:block" />

        <div className="flex-1 flex items-center justify-end space-x-3">
          <div className="hidden xl:flex xl:min-h-[72px]">
            {menu.slice(5).map(item => (
              <MenuPopover key={item.name} item={item}>
                <span className="block text-menu-body uppercase p-6 border-2 border-transparent hover:border-b-black">
                  {item.name}
                </span>
              </MenuPopover>
            ))}
          </div>

          <div className="lg:space-x-6 space-x-4 flex">
            <MenuSearch />
            <Account />
            <MenuCart />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
