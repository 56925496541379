import clsx from 'clsx';
import React, { PropsWithChildren, useId } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, 'type'> {
  error?: string;
}

const Checkbox: React.FC<PropsWithChildren<Props>> = ({
  name,
  error,
  required,
  children,
  className,
  ...rest
}) => {
  const id = useId();

  return (
    <div className="w-full flex items-center space-x-2">
      <input
        id={`${name}-${id}`}
        type="checkbox"
        name={name}
        className={clsx(
          twMerge('w-5 h-5 text-black border rounded', className),
          {
            'border-black focus:ring-black focus:border-black': !error,
            'border-red-500 focus:ring-red-500 focus:border-red-500': error
          }
        )}
        {...rest}
      />

      {children && (
        <label htmlFor={`${name}-${id}`} className="text-sm md:text-base">
          <span>{children}</span>
          {required && <span className="text-red-500"> *</span>}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
