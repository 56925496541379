import React from 'react';
import Link from 'next/link';

import localizedRoute from '@/utils/localizedRoute';

interface Props extends React.HTMLProps<HTMLAnchorElement> {}

const LinkTo: React.FC<Props> = ({ href, children, ...props }) => {
  const localizedHref = localizedRoute(href);

  return (
    <Link href={localizedHref} prefetch={false}>
      <a {...props}>{children}</a>
    </Link>
  );
};

export default LinkTo;
