import { Store } from 'redux';
import { CLEAR_STATE } from '../context/types';

export const logoutActions = (
  removeCookie: (name: any, options?: any) => void,
  store: Store
) => {
  removeCookie('uyn_country', { path: '/' });
  removeCookie('uyn_authenticated', { path: '/' });
  removeCookie('uyn_bearer', { path: '/' });
  store.dispatch({ type: CLEAR_STATE });
};
