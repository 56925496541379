import dynamic from 'next/dynamic';
import React, { Fragment, useState } from 'react';
import { PauseIcon, PlayIcon } from '@heroicons/react/solid';

const Player = dynamic(() => import('react-player'), { ssr: false });

interface Props {
  src: string;
  autoPlay?: boolean;
}

const VideoPlayer: React.FC<Props> = ({ src, autoPlay = false }) => {
  const [playing, setPlaying] = useState(autoPlay);

  function togglePlaying() {
    setPlaying(playing => !playing);
  }

  return (
    <div className="group relative w-full">
      <Player
        url={src}
        playing={playing}
        muted={autoPlay}
        loop={autoPlay}
        playsinline={autoPlay}
        height="100%"
        width="100%"
      />

      {!autoPlay && (
        <Fragment>
          {playing ? (
            <button
              onClick={togglePlaying}
              className="hidden absolute left-6 bottom-6 group-hover:block"
            >
              <PauseIcon className="h-20 w-20 text-white/70" />
            </button>
          ) : (
            <button
              onClick={togglePlaying}
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >
              <PlayIcon className="h-20 w-20 text-white/70" />
            </button>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default VideoPlayer;
