import React from 'react';
import { useTranslation } from 'react-i18next';
import LinkTo from '@/components/LinkTo';

export default function Logo(props: { containerClassName: string }) {
  const { t } = useTranslation();
  return (
    <LinkTo
      href="/"
      aria-label={t('GO_TO_HOME')}
      className={
        'focus:outline-none w-24 inline-flex items-center ' +
        props.containerClassName
      }
    >
      <svg
        className="h-7"
        viewBox="0 0 712 193"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M612.1 0.310049C539.65 0.310049 513.43 6.21005 499.33 57.35C491.79 83.25 483.92 144.88 483.92 170.78C483.92 178.83 484.4 185.87 485.45 192.01H532.36C532.36 192.01 539.78 136.54 542.09 118.31C544.66 98.07 547.9 77.28 550.14 68.82C557.35 43.25 569.81 41.94 607.51 41.94C645.21 41.94 657.67 43.25 657.67 71.44C657.67 80.15 655.86 99.31 653.39 118.41C650.93 137.42 643.68 192 643.68 192H690.52C692.28 187.85 693.89 183.31 695.37 178.31C704.22 151.1 711.76 90.12 711.76 63.24C711.76 6.53005 687.5 0.300049 612.1 0.300049V0.310049Z"
          fill="black"
        />
        <path
          d="M180.07 0.370117C180.07 0.370117 172.59 56.6801 170.22 75.3101C167.74 94.7801 164.54 114.45 161.86 123.17C154.65 149.07 142.19 150.38 105.15 150.38C68.1098 150.38 54.6598 148.74 54.6598 120.55C54.6598 112.44 56.5098 93.9801 58.9098 75.3001C61.4498 55.4801 68.7698 0.380117 68.7698 0.380117H21.1098C19.2198 4.96012 17.5298 10.0301 15.9798 15.6501C8.43982 41.5501 0.569824 103.18 0.569824 129.08C0.569824 185.79 24.4998 192.02 99.8998 192.02C170.05 192.02 197.26 186.45 212.02 136.62C220.87 109.41 228.41 48.4301 228.41 21.5501C228.41 13.5201 227.92 6.51012 226.87 0.380117H180.07V0.370117Z"
          fill="black"
        />
        <path
          d="M425.51 0.310059C423.77 13.4901 422.07 26.5301 421.14 33.8101C418.66 53.2801 415.46 72.9501 412.78 81.6701C405.57 107.57 393.11 108.88 356.07 108.88C319.03 108.88 305.58 107.24 305.58 79.0501C305.58 70.9401 307.43 52.4801 309.83 33.8001C310.81 26.1601 312.5 13.2701 314.21 0.320058H266.33C264.43 4.91006 262.73 10.0001 261.18 15.6501C258.83 23.7301 256.43 33.0101 254.27 48.1701C252.12 63.3301 251.5 78.0801 251.5 87.5801C251.5 136.75 269.5 147.97 323.42 150.09L317.9 192H371.45L376.96 150.13C428.09 148.22 450.18 138.23 462.94 95.1301C465.89 86.0501 469.46 71.4601 471.13 59.1301C472.8 46.7901 473.61 30.9101 473.61 21.5701C473.61 13.5201 473.12 6.49006 472.06 0.340058H425.51V0.310059Z"
          fill="black"
        />
      </svg>
    </LinkTo>
  );
}
