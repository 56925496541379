/* eslint-disable no-empty */
/* eslint-disable unicorn/prefer-optional-catch-binding */
/* This example requires Tailwind CSS v2.0+ */
import Image from 'next/future/image';
import React, { Fragment, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { ShoppingBagIcon } from '@heroicons/react/outline';
import usePrice from '../../hooks/usePrice';

import { ICartProduct, ICart } from '../../models/cart';
import {
  clearGiftProduct,
  removeProduct,
  updateProduct
} from '../../fetchers/cart';
import { getLangCountry } from '../../helpers/languages';
import useQueryParams from '../../hooks/useQueryParams';
import { SET_SESSION_CART } from '../../context/types';

import payWithIcon from '../../public/img/pay-with.svg';

import CartProduct from './CartProduct';
import { useAlert } from '../../context/AlertProvider';
import { useTranslation } from 'react-i18next';
import MiniSpinner from '../MiniSpinner';
import { useSelector, useStore } from 'react-redux';
import { IGlobalState } from '../../context/reducer';
import LinkTo from '../LinkTo';
import { getToken } from '../../helpers/auth';
import WidgetScalapay from '../WidgetScalapay';
import FreeCartProduct from './FreeCartProduct';
import BannerProduct from './BannerProduct';

import { XIcon } from '@heroicons/react/solid';
import { Button, buttonVariants } from '../v2/UI/Button';
import ProgressBar from '../v2/UI/ProgressBar';
import PopupShippingUs from '../v2/PopupShippingUS';

export default function MenuCart() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formatPrice] = usePrice();
  const [query] = useQueryParams();
  const { t } = useTranslation();
  const { lang, country } = getLangCountry(query);
  const alert = useAlert();
  const store = useStore();
  const { session = {} } = useSelector<IGlobalState, IGlobalState>(
    state => state || {}
  );

  const {
    products = [],
    gift_products,
    total,
    subtotal,
    remaining = 0,
    shipping = 0,
    total_with_discounts = 0,
    discount_total = 0,
    free_shipping_promotion = false,
    needs_eu_shipping = false
  } = (session as ICart)?.cart || {};

  const token = getToken();
  const CHECKOUT_URL =
    country === 'us'
      ? `${process.env.NEXT_PUBLIC_UYN_BASE_URL_US}/create-order?session_token=${token}`
      : `${process.env.NEXT_PUBLIC_UYN_BASE_URL}/create-order?session_token=${token}`;

  const sendCheckoutEv = () => {
    //PIXEL EVENT Checkout
    // pixelFB.event({ eventName: 'InitiateCheckout' });
  };

  const onChangeQty = async (product: ICartProduct, qty: number) => {
    try {
      setLoading(true);
      const cart = await updateProduct({
        ...getLangCountry(query),
        token,
        sku: product['product-sku'],
        quantity: qty
      });
      store.dispatch({ type: SET_SESSION_CART, payload: cart });
    } catch (error) {
      alert.error(t('GENERIC_ERROR'));
    } finally {
      setLoading(false);
    }
  };

  const onRemoveProduct = async (product: ICartProduct) => {
    try {
      setLoading(true);
      const cart = await removeProduct({
        ...getLangCountry(query),
        token,
        sku: product['product-sku']
      });

      store.dispatch({ type: SET_SESSION_CART, payload: cart });
    } catch (error) {
      alert.error(t('GENERIC_ERROR'));
    } finally {
      setLoading(false);
    }
  };

  const onClearProduct = async (promotion_id: number) => {
    try {
      setLoading(true);
      const cart = await clearGiftProduct({
        ...getLangCountry(query),
        token,
        promotion_id: promotion_id
      });

      store.dispatch({ type: SET_SESSION_CART, payload: cart });
    } catch (error) {
      alert.error(t('GENERIC_ERROR'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button
        id="menu-cart"
        onClick={() => setOpen(true)}
        className="relative hover:text-gray-500"
      >
        <ShoppingBagIcon className="w-6 h-6" />
        <div className="absolute -bottom-1 -right-1 w-4 h-4 text-white bg-black flex items-center justify-center rounded-full text-[9px] font-semibold">
          {products.length}
        </div>
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed z-60 inset-0 bg-black/30" />
          </Transition.Child>

          <div className="w-full fixed z-60 inset-y-0 right-0 xl:w-1/2">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="h-full flex flex-col bg-white shadow-xl divide-y">
                <div className="flex justify-between items-center px-4 py-4 md:px-12">
                  <Dialog.Title className="h5">{t('CART')}</Dialog.Title>

                  <button onClick={() => setOpen(false)}>
                    <XIcon className="w-6 h-6" />
                  </button>
                </div>

                {remaining > 0 && (
                  <div className="space-y-3 px-4 py-4 md:px-12">
                    <p>
                      {t('CART_SHIPPING_FREE_MESSAGE_1')}{' '}
                      {formatPrice(Math.max(0, remaining))}{' '}
                      {t('CART_SHIPPING_FREE_MESSAGE_2')}
                    </p>

                    <div className="rounded-full overflow-hidden">
                      <ProgressBar value={(remaining / 49) * 100} />
                    </div>
                  </div>
                )}

                <div className="flex-1 overflow-y-scroll">
                  {products.length > 0 ? (
                    <div>
                      <div>
                        {gift_products?.banners?.map((banner, idx) =>
                          Object.keys(banner).length > 0 &&
                          Object.keys(banner).includes(lang) ? (
                            <BannerProduct
                              key={idx}
                              onClickFunction={() => setOpen(false)}
                              {...banner[lang]}
                            />
                          ) : (
                            <></>
                          )
                        )}
                      </div>

                      <ul className="divide-y">
                        {gift_products?.products?.map(product => (
                          <li
                            key={product['product-sku']}
                            className="flex space-x-3 md:space-x-6 px-4 py-4 sm:px-12"
                          >
                            <FreeCartProduct
                              product={product}
                              onClearProduct={onClearProduct}
                            />
                          </li>
                        ))}

                        {products.map((product: ICartProduct) => (
                          <CartProduct
                            key={product['product-sku']}
                            product={product}
                            onChangeQty={onChangeQty}
                            onRemoveProduct={onRemoveProduct}
                          />
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div className="flex justify-center items-center h-full">
                      <p>{t('EMPTY_CART')}</p>
                    </div>
                  )}
                </div>

                <div className="space-y-6 px-4 py-6 md:px-12">
                  <div className="space-y-6">
                    <div className="space-y-3">
                      <div className="flex justify-between text-md font-medium text-gray-500">
                        <p>{t('SUBTOTAL')}</p>
                        <p>{formatPrice(subtotal)}</p>
                      </div>

                      <div className="flex justify-between text-md font-medium text-gray-500">
                        <p> {t('SHIPPING_FEES')} </p>
                        <p className="flex items-center">
                          {needs_eu_shipping ? <PopupShippingUs /> : <></>}
                          <span className="ml-1">{formatPrice(shipping)}</span>
                        </p>
                      </div>

                      {country === 'ch' && (
                        <div className="flex justify-between font-medium text-gray-500 px-3 py-2 bg-gray-50 rounded-sm">
                          <p className="text-sm">{t('P_SHIPPING.SWISS_TEXT')}</p>
                        </div>
                      )}

                      {/** TODO: PROMO DISCOUNT */}
                      {discount_total !== 0 && (
                        <div className="flex justify-between text-md font-medium text-gray-500">
                          <p>{t('PROMO_DISCOUNT')}</p>
                          <p>{formatPrice(discount_total)}</p>
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col space-y-3">
                      <div className="flex justify-between items-end">
                        <p className="font-bold">{t('TOTAL')}</p>

                        <p className="inline-flex items-center">
                          {loading && <MiniSpinner />}
                          <span className="inline-block ml-3 text-3xl font-bold">
                            {products.length > 0
                              ? total_with_discounts !== 0
                                ? formatPrice(total_with_discounts)
                                : formatPrice(total)
                              : formatPrice(0)}
                          </span>
                        </p>
                      </div>

                      <div className="text-right">
                        {total_with_discounts !== 0 ? (
                          <WidgetScalapay
                            lang={lang}
                            amount={total_with_discounts}
                          />
                        ) : (
                          <WidgetScalapay lang={lang} amount={total} />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <LinkTo
                      href={CHECKOUT_URL}
                      onClick={sendCheckoutEv}
                      aria-label={t('GO_TO_CHECKOUT')}
                      className={buttonVariants({ variant: 'dark' })}
                    >
                      {t('GO_TO_CHECKOUT')}
                    </LinkTo>

                    <Button onClick={() => setOpen(false)} className="w-full">
                      {t('CONTINUE_SHOPPING')}
                    </Button>
                  </div>

                  <div className="flex flex-col items-center space-y-3 space-x-2 md:space-y-0 md:flex-row">
                    <p className="text-md text-gray-600">{t('PAY_SECURE')}</p>
                    <Image
                      src={payWithIcon}
                      className="w-60"
                      alt="Payment options"
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
