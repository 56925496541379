import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchColors } from '../../fetchers/mapping';
import { ICartProduct, ICartProductGift } from '../../models/cart';
import Image from 'next/future/image';
import localizedRoute from '../../utils/localizedRoute';
import LinkTo from '../LinkTo';
import MiniSpinner from '../MiniSpinner';
import { TrashIcon } from '@heroicons/react/outline';

const FreeCartProduct = ({
  product,
  onClearProduct
}: {
  product: ICartProductGift;
  onClearProduct: (promotion_id: number) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const colorsCodeToLabel = fetchColors();

  const getColorLabel = (color_code: string) => {
    const { color = '' } = colorsCodeToLabel[color_code] || {};
    return color;
  };

  const clearProduct = async () => {
    setLoading(true);
    await onClearProduct(Number.parseInt(product.promotion || '0'));
    setLoading(false);
  };
  if (!product || !product.selected || product.selected.length === 0)
    return <></>;

  return (
    <>
      <Image
        src={product.image}
        alt={product.name}
        width={0}
        height={0}
        sizes="100vw"
        className="w-28 h-auto object-contain"
      />
      <div className="flex-1 flex flex-col space-y-3 md:space-y-6">
        <div>
          <h3 className="text-sm md:text-base">
            <LinkTo href={product.slug}>{product.name}</LinkTo>
          </h3>

          <p className="text-sm text-gray-500 md:text-base">
            <span>
              {t('SIZE')}: {product.size}
            </span>

            <span> - </span>

            <span>
              {t('COLOR')} : {getColorLabel(product.color)}
            </span>
          </p>
          <div className="flex-1 flex items-center space-x-3 text-sm mt-1">
            <p className="text-xs font-bold tracking-uyn uppercase bg-black rounded-uyn  text-white px-3 py-1.5">
              {t('PRODUCT_FREE')}
            </p>
            {product.products &&
              product.products.length > 1 &&
              product.selected &&
              product.selected.length > 0 && (
                <div className="flex">
                  <button onClick={clearProduct} className="flex">
                    {loading ? (
                      <MiniSpinner />
                    ) : (
                      <span className="flex space-x-1">
                        <TrashIcon className="w-5 h-5" />
                        <span className="underline">{t('REMOVE')}</span>
                      </span>
                    )}
                  </button>
                  {/* <button
                    type="button"
                    className="font-medium text-primary inline-flex items-center mb-1"
                    onClick={clearProduct}
                  >
                    {loading && <MiniSpinner />}
                    {t('REMOVE')}
                  </button> */}
                </div>
              )}
          </div>
        </div>
      </div>
      {/* <div className="flex-shrink-0 w-16 h-16 lg:w-24 lg:h-24 border border-gray-200 rounded-md overflow-hidden">
        <img
          src={product.image}
          alt={product.name}
          className="w-full h-full object-center object-cover"
        />
      </div> */}

      {/* <div className="ml-2 lg:ml-4 flex-1 flex flex-col">
        <div className="text-sm font-medium text-gray-900">
          <h3>
            <LinkTo href={product.slug}>{product.name}</LinkTo>
          </h3>

          <p className="mt-1 text-xs text-gray-500">
            {product.size} | {getColorLabel(product.color)}
          </p>
        </div>
        <div className="flex-1 flex items-end justify-between text-sm">
          <p className="text-sm mt-3 bg-blue-100 text-primary p-2 rounded-md">
            {t('PRODUCT_FREE')}
          </p>
          {product.products &&
            product.products.length > 1 &&
            product.selected &&
            product.selected.length > 0 && (
              <div className="flex">
                <button
                  type="button"
                  className="font-medium text-primary inline-flex items-center mb-1"
                  onClick={clearProduct}
                >
                  {loading && <MiniSpinner />}
                  {t('REMOVE')}
                </button>
              </div>
            )}
        </div>
      </div> */}
    </>
  );
};

export default FreeCartProduct;
