import { useQuery } from '@tanstack/react-query';
import {
  fetchShippingCost,
  fetchShippingCountriesList
} from '@/fetchers/shipping';
import { getLangCountry } from '@/helpers/languages';
import { useRouter } from 'next/router';
import { getCurrencySymbol } from '@/helpers/prices';

export function useShippingRate() {
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);

  return useQuery(
    ['shipping-cost', lang, country],
    async () => {
      const countries = fetchShippingCountriesList({ lang });
      const currencySymbol = getCurrencySymbol(countries, country);
      const rate = await fetchShippingCost(country);

      return { rate, currencySymbol };
    },
    { enabled: !!country }
  );
}
