import React from 'react';

interface Props {
  value: number;
}

const ProgressBar: React.FC<Props> = ({ value }) => {
  return (
    <div role="progressbar" className="w-full bg-gray-200">
      <div
        className="h-2 bg-black"
        style={{ width: `${Math.min(Math.max(0, value), 100)}%` }}
      />
    </div>
  );
};

export default ProgressBar;
