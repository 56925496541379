import menu from '@/public/menu/menu.json';
import menuUs from '@/public/menu/menu.json';

import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { DEFAULT_LANG, getLangCountry } from '@/helpers/languages';

import { MenuItem } from '@/types/Menu';
import { getAPIClient } from '@/lib/api';
import { filter, lowerCase, map } from 'lodash';

export function useMenu() {
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);

  const _menu = lowerCase(country) === 'us' ? menuUs : menu;

  return useQuery(
    ['menu', lang, country],
    async () => {
      const apiClient = getAPIClient({ country });

      const { data } = await apiClient.get<{
        menu: { [key: string]: MenuItem[] };
      }>('/advanced-api/api/v2/menu');

      return filterMenuItems(
        data.menu[lang] ? data.menu[lang] : data.menu[DEFAULT_LANG]
      );
    },
    {
      refetchOnMount: 'always',
      initialData: filterMenuItems(
        _menu[lang] ? _menu[lang] : _menu[DEFAULT_LANG]
      )
    }
  );
}

function filterMenuItems(items: MenuItem[]): MenuItem[] {
  return map(
    filter(
      items,
      item =>
        typeof item.category_count === 'undefined' || item.category_count > 0
    ),
    item => ({
      ...item,
      children: item.children ? filterMenuItems(item.children) : undefined,
      highlights: item.highlights
        ? filterMenuItems(item.highlights)
        : undefined,
      collabs: item.collabs ? filterMenuItems(item.collabs) : undefined,
      previews: item.previews ? filterMenuItems(item.previews) : undefined
    })
  );
}
