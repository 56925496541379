import React from 'react';
import { getLangCountry } from '../../helpers/languages';
import useQueryParams from '../../hooks/useQueryParams';
import { GiftBanner } from '../../models/cart';

import { buttonVariants } from '../v2/UI/Button';
import Link from 'next/link';

interface Props extends GiftBanner {
  onClickFunction?: () => void;
}

const BannerProduct: React.FC<Props> = ({
  title,
  disclaimer,
  text,
  button_text,
  button_link,
  button_text_2,
  button_link_2,
  image,
  product_slug,
  onClickFunction
}) => {
  const [query] = useQueryParams();
  const { lang, country } = getLangCountry(query);
  if (product_slug) {
    //button_link = `/${lang}_${country}/${product_slug}`;
  }

  return (
    <div className="md:flex md:space-y-0 md:space-x-4 px-4 md:px-12 py-3 bg-black">
      <div className="flex justify-center">
        <img
          src={image}
          alt="Banner product"
          className="h-16 xl:h-20 bg-white"
        />
      </div>
      <div className="flex-1 flex flex-col justify-center">
        <div className="flex items-center space-x-1 text-white">
          <div className="h6">{title}</div>
          <p
            className=""
            dangerouslySetInnerHTML={{
              __html: text
            }}
          ></p>
        </div>

        {disclaimer && (
          <p
            className="text-sm text-center lg:text-left text-white"
            dangerouslySetInnerHTML={{
              __html: disclaimer
            }}
          ></p>
        )}

        <div className="flex gap-x-2">
          {button_text && button_text.length > 0 && (
            <div
              className="mt-2 lg:inline-flex"
              onClick={() => {
                if (onClickFunction) {
                  onClickFunction();
                }
              }}
            >
              <Link href={button_link}>
                <a className={buttonVariants({ variant: 'light' })}>
                  {button_text}
                </a>
              </Link>
            </div>
          )}

          {button_text_2 && button_text_2.length > 0 && button_link_2 && (
            <div className="mt-2 lg:inline-flex">
              <Link href={button_link_2}>
                <a className={buttonVariants({ variant: 'light' })}>
                  {button_text_2}
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerProduct;
