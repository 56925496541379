import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { cva, VariantProps } from 'class-variance-authority';

import MiniSpinner from '@/components/MiniSpinner';

const buttonVariants = cva(
  'inline-block text-btn border text-center uppercase space-x-1',
  {
    variants: {
      variant: {
        light:
          'text-xs font-bold bg-white text-black px-3 py-2 md:px-6 md:py-3 rounded hover:bg-gray-100 disabled:bg-gray-100 cursor-pointer',
        dark: 'text-xs font-bold bg-black text-white p-3 py-2 md:px-6 md:py-3 rounded hover:bg-gray-950 disabled:bg-gray-500 cursor-pointer',
        'dark-big':
          'text-sm font-bold bg-black text-white p-3 py-3 md:px-6 md:py-4 rounded hover:bg-gray-950 disabled:bg-gray-500 cursor-pointer',
        'white-big':
          'text-sm font-bold bg-white text-black p-3 py-3 md:px-6 md:py-4 rounded hover:bg-white disabled:bg-gray-500 cursor-pointer',
        'outline-light':
          'text-base transition-all duration-300 tracking-normal font-bold bg-transparent text-white font-light px-8 py-4 rounded-full hover:bg-white hover:text-black disabled:bg-gray-100 cursor-pointer',
        secondary:
          'bg-white text-black p-2.5 rounded-full md:p-4 disabled:bg-gray-100 cursor-pointer',
        'secondary-dark':
          'bg-black text-white p-2.5 rounded-full md:p-4 disabled:bg-gray-500 cursor-pointer',
        'undercontrol-light':
          'bg-[#F2D7B9] text-[#081923] px-4 py-3 rounded-xl md:px-6 md:py-5 border-none disabled:bg-gray-100 cursor-pointer hover:bg-[#CCB397] transition-all duration-300',
        'undercontrol-dark':
          'bg-[#081923] text-[#F2D7B9] px-4 py-3 rounded-xl md:px-6 md:py-5 border-none disabled:bg-gray-100 cursor-pointer hover:bg-[#0B2433] transition-all duration-300'
      }
    },
    defaultVariants: {
      variant: 'light'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, className, children, loading, ...props }, ref) => (
    <button
      ref={ref}
      className={twMerge(buttonVariants({ variant }), className)}
      {...props}
    >
      {loading ? <MiniSpinner /> : children}
    </button>
  )
);

Button.displayName = 'Button';

export { Button, buttonVariants };
