import React from 'react';

import BannerShipping from './BannerShipping';

import Navbar from './Navbar';

const Header = () => {
  return (
    <header>
      <BannerShipping />
      <Navbar />
    </header>
  );
};

export default Header;
