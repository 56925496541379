import React from 'react';
import { useCookies } from 'react-cookie';

const WHITE_LIST = new Set([
  'it',
  'en',
  'de',
  'fr',
  'sv',
  'no',
  'cs',
  'sk',
  'es',
  'da',
  'pl',
  'pt',
  'ru',
  'hu',
  'nl'
]);
interface WidgetScalapayProps {
  amount: number;
  lang: string;
}

const WidgetScalapay: React.FC<WidgetScalapayProps> = ({ amount, lang }) => {
  const [cookies] = useCookies(['uyn_actual_country']);

  if (!WHITE_LIST.has(cookies['uyn_actual_country'])) {
    return null;
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html:
          '<div class="text-sm"><scalapay-widget amount="' +
          amount +
          '" locale="' +
          lang +
          '" ></scalapay-widget></div>'
      }}
    ></div>
  );
};

export default WidgetScalapay;
