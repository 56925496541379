import moment from 'moment';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';

import { ChevronRightIcon, XIcon } from '@heroicons/react/solid';

import Media from './Media';
import { buttonVariants } from './UI/Button';

import { usePopup } from '@/hooks/popups/use-popup';
import { useCookies } from 'react-cookie';

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: popup, isLoading } = usePopup();
  const [cookies, setCookies] = useCookies(['uyn_popup']);

  useEffect(() => {
    if (popup && !cookies.uyn_popup) {
      setTimeout(() => {
        setIsOpen(true);
      }, 4000);
    }
  }, [popup, cookies.uyn_popup]);

  function closePopup() {
    setCookies('uyn_popup', true, {
      expires: moment().add(14, 'days').toDate()
    });

    setIsOpen(false);
  }

  if (!popup || isLoading) {
    return null;
  }

  const mediaSource = {
    mobile: {
      type: 'image',
      src: popup.img_mobile,
      alt: popup.main_title
    },
    tablet: {
      type: 'image',
      src: popup.img_tablet,
      alt: popup.main_title
    },
    desktop: {
      type: 'image',
      src: popup.img_desk,
      alt: popup.main_title
    }
  };

  return (
    <Dialog open={isOpen} onClose={closePopup} className="relative z-50">
      <Dialog.Backdrop className="fixed inset-0 bg-black/30" />

      <div className="fixed inset-0 flex items-center justify-center px-6">
        <Dialog.Panel className="w-full max-w-4xl bg-black rounded-lg overflow-hidden">
          <div className="grid md:grid-cols-2">
            <Media
              source={mediaSource}
              className="h-full order-last md:order-first"
            />

            <div className="order-first flex flex-col text-white p-6 md:order-last">
              <div className="w-full flex justify-end">
                <button onClick={closePopup}>
                  <XIcon className="w-5 h-5" />
                </button>
              </div>

              <div className="flex-1 flex flex-col justify-center space-y-6 p-3 md:p-6">
                <div className="space-y-6">
                  <div className="h3 text-white ">{popup.main_title}</div>
                  <p className="md:text-2xl uppercase tracking-wide text-white">
                    {popup.description}
                  </p>
                </div>

                <Link href={popup.cta_link} aria-label={popup.cta_text}>
                  <a
                    className={buttonVariants({
                      variant: 'light',
                      className: 'flex items-center'
                    })}
                  >
                    <span>{popup.cta_text}</span>
                    <ChevronRightIcon className="h-4 w-4" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default Popup;
