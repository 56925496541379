import React from 'react';
import Image from 'next/future/image';
import { useTranslation } from 'react-i18next';

import LinkTo from '@/components/LinkTo';
import { useShippingRate } from '@/hooks/use-shipping-rate';

import MapImg from '@/public/img/map.svg';

const BannerShipping = () => {
  const { t } = useTranslation();
  const { data: { rate, currencySymbol } = {}, isLoading } = useShippingRate();

  if (isLoading) {
    return null;
  }

  return (
    <section className="text-center bg-black text-white py-2 px-2 grid lg:grid-cols-3 grid-cols-2 text-xs lg:font-bold font-medium uppercase lg:tracking-uyn tracking-wide">
      <div className="col-span-1 text-left hidden lg:block"></div>
      <div className="lg:col-span-1 col-span-2 text-center space-x-2">
        {rate?.free !== -1 && (
          <span>
            {t('S_TOPBAR.SHIPPING')} {`${rate?.free}${currencySymbol}`} |
          </span>
        )}

        <span>{t('S_TOPBAR.RETURNS')}</span>
      </div>

      <div className="col-span-1 hidden lg:block">
        <LinkTo
          href="/store-locator"
          aria-label={t('P_HOME.SECTION-1.ARTICLE.CTA')}
          className="flex items-center space-x-1 ml-auto w-max"
        >
          <Image src={MapImg} alt="" className="w-4 h-4 text-white" />
          <span>{t('S_TOPBAR.MAP')}</span>
        </LinkTo>
      </div>
    </section>
  );
};

export default BannerShipping;
