import dynamic from 'next/dynamic';
import Image from 'next/future/image';
import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { useMediaQuery } from 'usehooks-ts';

import VideoPlayer from './VideoPlayer';

export type Media = {
  type: string;
  src: string;
  alt: string;
};

interface Props {
  source: {
    mobile: Media;
    tablet: Media;
    desktop: Media;
  };
  className?: string;
}

const Media: React.FC<Props> = ({ source, className }) => {
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1279px)');
  const isDesktop = useMediaQuery('(min-width: 1280px)');

  const el = useMemo(() => {
    const { type, src, alt } = isTablet
      ? source.tablet
      : isDesktop
      ? source.desktop
      : source.mobile;

    if (type === 'image') {
      return (
        <Image
          src={src}
          alt={alt}
          width={0}
          height={0}
          sizes="100vw"
          priority
          className={twMerge('w-[100%] h-full object-cover', className)}
        />
      );
    }

    return <VideoPlayer src={src} autoPlay />;
  }, [isTablet, isDesktop]);

  return el;
};

export default dynamic(() => Promise.resolve(Media), { ssr: false });
