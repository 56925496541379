import React from 'react';
import Image from 'next/future/image';
import { get } from 'lodash';

import { MenuItem } from '@/types/Menu';
import LinkTo from '@/components/LinkTo';
import clsx from 'clsx';

interface Props {
  item: MenuItem;
  toggleOpen: () => void;
}

const Previews: React.FC<Props> = ({ item, toggleOpen }) => {
  return (
    <ul className="space-y-3 py-10 pl-0 pr-8 xl:w-[20vw]">
      {item.previews?.map((preview, idx) => {
        if (!preview.image) {
          return null;
        }

        return (
          <li key={idx} className="relative">
            <div
              className={clsx('absolute inset-0', {
                'bg-black/10': preview.name
              })}
            />
            {/* <div className={clsx('absolute inset-0', preview.image : 'bg-black/60')} /> */}

            <Image
              src={preview.image}
              alt={get(item, 'name', '')}
              width={0}
              height={0}
              sizes="100vw"
              className="h-auto w-full object-cover"
            />

            {preview.href ? (
              <LinkTo
                href={preview.href}
                aria-label={preview.name}
                onClick={toggleOpen}
                className="absolute inset-0 flex justify-center items-center"
              >
                {preview.name ? (
                  <span className="text-base uppercase font-bold text-white tracking-uyn drop-shadow-md">
                    {preview.name}
                  </span>
                ) : (
                  ''
                )}
              </LinkTo>
            ) : (
              <div className="absolute inset-0 flex justify-center items-center">
                <p className="text-base uppercase font-bold text-white tracking-uyn drop-shadow-md">
                  {preview.name}
                </p>
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Previews;
